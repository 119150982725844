exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-twitter-tsx": () => import("./../../../src/pages/twitter.tsx" /* webpackChunkName: "component---src-pages-twitter-tsx" */)
}

